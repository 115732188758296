import React from "react";

import { FaLinkedinIn, FaFacebookF, FaYoutube } from "react-icons/fa";

import { FaXTwitter } from "react-icons/fa6";

export default function SocialMediaLinks() {
  return (
    <>
      <div className="fixed right-0 z-50 hidden md:block top-1/3">
        <a href="https://twitter.com/novacomcorp" target="_blank" rel="me">
          <FaXTwitter className="p-2.5 text-4xl text-gray-400 bg-gray-800 transition hover:text-novaBlue duration-300" />
        </a>
        <a href="https://www.linkedin.com/company/novacomcorp" target="_blank" rel="me">
          <FaLinkedinIn className="p-2.5 text-4xl text-gray-400 bg-gray-800 transition hover:text-novaBlue duration-300" />
        </a>
        <a href="https://www.facebook.com/novacomcorp" target="_blank" rel="me">
          <FaFacebookF className="p-2.5 text-4xl text-gray-400 bg-gray-800 transition hover:text-novaBlue duration-300" />
        </a>
        <a href="https://www.youtube.com/user/novacomcorp" target="_blank" rel="me">
          <FaYoutube className="p-2.5 text-4xl text-gray-400 bg-gray-800 transition hover:text-novaBlue duration-300" />
        </a>
      </div>
    </>
  );
}
