import React from "react";

import { HiMail } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { NewsletterFooterForm } from "../forms/NewsletterFooterForm";
import { useGlobalContext } from "../utils/context";
import SpringDown from "../utils/springDown";
import SpringUp from "../utils/springUp";

export default function NewsletterSignup() {
  const { newsletterisToggled, setNewsletter } = useGlobalContext();

  return (
    <>
      <div id="newsletter-signup" className="container absolute z-30 mx-auto">
        <div className="grid grid-cols-12">
          <div className="col-span-10 col-start-2 lg:col-span-3 lg:col-start-9 xl:col-span-2 xl:col-start-10 md:col-span-4 md:col-start-8">
            <div className="bg-gray-900 -mt-9">
              <button className="block py-2 mx-auto" onClick={setNewsletter}>
                <span className="block mx-auto text-sm font-light text-center text-white">
                  {!newsletterisToggled ? (
                    <HiMail className="inline-block mr-3 text-xl" />
                  ) : (
                    <CgClose className="inline-block mr-2 text-lg" />
                  )}
                  {!newsletterisToggled ? "Sign up to our newsletter" : "Close"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {newsletterisToggled ? (
        <SpringDown>
          <div className="z-10 bg-gray-900">
            <div className="container py-2 mx-auto">
              <NewsletterFooterForm formID={1155} />
            </div>
          </div>
        </SpringDown>
      ) : (
        <SpringUp>
          <div className="z-10 bg-gray-900">
            <div className="container py-2 mx-auto">
              <NewsletterFooterForm formID={1155} />
            </div>
          </div>
        </SpringUp>
      )}
    </>
  );
}
