import React, { useState, useContext } from "react";

const SiteContext = React.createContext();

const SiteProvider = ({ children }) => {
  const [searchIsToggled, setSearchIsToggled] = useState(false);
  const [navBarIsToggled, setNavBarIsToggled] = useState(false);
  const [newsletterisToggled, setNewsletterIsToggled] = useState(false);
  const [subNavIsToggled, setSubNavIsToggled] = useState(false);
  const [aboutMenuIsToggled, setAboutMenuIsToggled] = useState(false);
  const [servicesMenuIsToggled, setServicesMenuIsToggled] = useState(false);
  const [industriesMenuIsToggled, setIndustriesMenuIsToggled] = useState(false);
  const [caseStudiesMenuIsToggled, setCaseStudiesMenuIsToggled] =
    useState(false);

  const setSearch = () => {
    setSearchIsToggled(!searchIsToggled);
  };
  const openSearch = () => {
    setSearchIsToggled(true);
  };
  const closeSearch = () => {
    setSearchIsToggled(false);
  };
  const defaultSearch = () => {
    setSearchIsToggled("default");
  };

  const setNavBar = () => {
    setNavBarIsToggled(!navBarIsToggled);
  };
  const openNavBar = () => {
    setNavBarIsToggled(true);
  };
  const closeNavBar = () => {
    setNavBarIsToggled(false);
  };

  const setSubNav = () => {
    setSubNavIsToggled(!subNavIsToggled);
  };
  const closeSubNav = () => {
    setSubNavIsToggled(false);
  };

  const setNewsletter = () => {
    setNewsletterIsToggled(!newsletterisToggled);
  };
  const openNewsletter = () => {
    setNewsletterIsToggled(true);
  };
  const closeNewsletter = () => {
    setNewsletterIsToggled(false);
  };

  const setAboutMenu = () => {
    setAboutMenuIsToggled(true);
  };

  const closeAboutMenu = () => {
    setAboutMenuIsToggled(false);
  };

  const setServicesMenu = () => {
    setServicesMenuIsToggled(true);
  };

  const closeServicesMenu = () => {
    setServicesMenuIsToggled(false);
  };

  const setIndustriesMenu = () => {
    setIndustriesMenuIsToggled(true);
  };

  const closeIndustriesMenu = () => {
    setIndustriesMenuIsToggled(false);
  };

  const setCaseStudiesMenu = () => {
    setCaseStudiesMenuIsToggled(true);
  };

  const closeCaseStudiesMenu = () => {
    setCaseStudiesMenuIsToggled(false);
  };

  return (
    <SiteContext.Provider
      value={{
        searchIsToggled,
        navBarIsToggled,
        newsletterisToggled,
        subNavIsToggled,
        aboutMenuIsToggled,
        servicesMenuIsToggled,
        industriesMenuIsToggled,
        caseStudiesMenuIsToggled,
        setSearch,
        openSearch,
        closeSearch,
        setNavBar,
        closeNavBar,
        openNavBar,
        setSubNav,
        closeSubNav,
        setNewsletter,
        setAboutMenu,
        closeAboutMenu,
        setServicesMenu,
        closeServicesMenu,
        setIndustriesMenu,
        closeIndustriesMenu,
        setCaseStudiesMenu,
        closeCaseStudiesMenu,
        openNewsletter,
        closeNewsletter,
        defaultSearch,
      }}>
      {children}
    </SiteContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(SiteContext);
};

export { SiteContext, SiteProvider };
