import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

export default function EngageList() {
  const { services } = useStaticQuery(graphql`
    query {
      services: allSanityServices(
        sort: { order: ASC }
        filter: { categories: { elemMatch: { title: { eq: "Engage" } } } }
      ) {
        nodes {
          title
          id
          categories {
            title
          }
          slug {
            current
          }
        }
      }
    }
  `);

  const theservices = services.nodes;

  return (
    <>
      <span className="inline-block pb-1 mb-3 font-medium border-b-2 text-md border-novaPurple">
        <Link to="/services/engage">Engage</Link>
      </span>
      <div className="grid grid-cols-2 gap-3">
        {theservices.map((services, i) => (
          <div className="col-span-1" key={i}>
            <Link to={`/services/engage#${services.slug.current}`}>
              <div className="inline-block font-light">
                <span className="py-2 pb-2 text-sm duration-200 border-b-2 border-transparent hover:border-novaPurple current:border-novaPurple hover:transition-all">
                  {services.title}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
