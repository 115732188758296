import React from "react";
import { useSpring, animated as a } from "react-spring";

const SpringDownSearch = ({ children }) => {
  const animatedProps = useSpring({
    from: { marginTop: -80, opacity: 1, zIndex: -10 },
    opacity: 1,
    marginTop: 0,
    reverse: false,
    zIndex: 10,
    config: { mass: 1, tension: 150, friction: 30 },
  });
  return <a.div style={{ ...animatedProps }}>{children}</a.div>;
};

export default SpringDownSearch;
