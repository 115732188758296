import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

export default function IndustriesListFooter() {
  const { industries } = useStaticQuery(graphql`
    query {
      industries: allSanityIndustries(sort: { title: ASC }) {
        nodes {
          title
          id
          slug {
            current
          }
        }
      }
    }
  `);

  const theindustries = industries.nodes;

  return (
    <>
      <span className="block pb-2 text-sm font-normal">Industries</span>
      <div>
        {theindustries.map((industries, i) => (
          <div key={i}>
            <Link to={`/industries/${industries.slug.current}`}>
              <div className="block font-light">
                <span className="block py-1.5 text-xs text-gray-500 hover:text-gray-800">
                  {industries.title}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
