exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-novacom-js": () => import("./../../../src/pages/about/about-novacom.js" /* webpackChunkName: "component---src-pages-about-about-novacom-js" */),
  "component---src-pages-about-careers-js": () => import("./../../../src/pages/about/careers.js" /* webpackChunkName: "component---src-pages-about-careers-js" */),
  "component---src-pages-about-recognition-js": () => import("./../../../src/pages/about/recognition.js" /* webpackChunkName: "component---src-pages-about-recognition-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-legal-and-privacy-js": () => import("./../../../src/pages/legal-and-privacy.js" /* webpackChunkName: "component---src-pages-legal-and-privacy-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-services-convert-js": () => import("./../../../src/pages/services/convert.js" /* webpackChunkName: "component---src-pages-services-convert-js" */),
  "component---src-pages-services-engage-js": () => import("./../../../src/pages/services/engage.js" /* webpackChunkName: "component---src-pages-services-engage-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-reach-js": () => import("./../../../src/pages/services/reach.js" /* webpackChunkName: "component---src-pages-services-reach-js" */),
  "component---src-pages-thank-you-general-contact-js": () => import("./../../../src/pages/thank-you-general-contact.js" /* webpackChunkName: "component---src-pages-thank-you-general-contact-js" */),
  "component---src-pages-thank-you-newsletter-js": () => import("./../../../src/pages/thank-you-newsletter.js" /* webpackChunkName: "component---src-pages-thank-you-newsletter-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/CaseStudies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-industries-js": () => import("./../../../src/templates/Industries.js" /* webpackChunkName: "component---src-templates-industries-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/Insights.js" /* webpackChunkName: "component---src-templates-insights-js" */)
}

