import React, { useState } from "react";
import { Link } from "gatsby";
import LatestInsightsMegaMenu from "../nav-lists/main-nav/LatestInsightsMegaMenu";
import ReachList from "../nav-lists/main-nav/reach-list";
import ConvertList from "../nav-lists/main-nav/convert-list";
import EngageList from "../nav-lists/main-nav/engage-list";
import IndustriesList from "../nav-lists/main-nav/industries-list";
import CaseStudiesList from "../nav-lists/main-nav/case-studies-list";

import { BsChevronDown, BsSearch } from "react-icons/bs";
import { HiOutlineMenu } from "react-icons/hi";
import { CgClose } from "react-icons/cg";

import SearchContainer from "../search/SearchContainer";
import { useGlobalContext } from "../utils/context";

import logo from "../images/novacom-logo.svg";
import SpringDownSearch from "../utils/springDownSearch";
import SpringUpSearch from "../utils/springUpSearch";

export default function Nav() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [isToggled, setIsToggled] = useState(false);

  const { searchIsToggled, setSearch } = useGlobalContext();

  return (
    <>
      {/* Google Tag Manager */}
      <noscript type="text/javascript">
        {` <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KRZPCG3"
height="0" width="0" style="display:none;visibility:hidden"></iframe> `}
      </noscript>

      <div className="hidden lg:block">
        <nav className="relative z-50 flex flex-wrap items-center justify-between bg-white">
          <div className="container flex flex-wrap items-center justify-between mx-auto">
            <div className="block lg:hidden">
              <button
                onClick={() => toggleExpansion(!isExpanded)}
                className="items-center block hover:text-black hover:border-black">
                <HiOutlineMenu className="inline-block ml-2 text-3xl text-novaBlue" />
              </button>
            </div>
            <div className="block gap-2 pt-4 pb-3 text-xl font-bold text-blue-600 lg:pl-0 lg:inline-block lg:py-6">
              <Link to="/">
                <img
                  src={logo}
                  alt="Novacom"
                  id="top"
                  className="lg:pb-2 lg:w-32 lg:mt-1 xl:mt-0 xl:-mb-2 xl:w-40"
                />
              </Link>
            </div>
            <div className="block lg:hidden">
              <button
                className="pb-2 font-light"
                onClick={() => setIsToggled(!isToggled)}>
                <BsSearch className="mr-2 text-2xl text-novaBlue" />
              </button>
            </div>

            <div
              className={`${
                isExpanded ? `block` : `hidden`
              } bg-white lg:pt-2 lg:flex-grow justify-end w-full block flex-grow lg:flex lg:items-center lg:w-auto px-3`}>
              <ul>
                <li className="flex justify-between py-3 pb-2 text-sm border-b-2 border-gray-300 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0">
                  <Link
                    className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all"
                    to="/about/about-novacom">
                    About
                  </Link>
                  <BsChevronDown className="inline-block ml-2 text-xs text-novaBlue" />
                  <div className="z-10 w-2/3 mb-16 bg-opacity-0 mega-menu">
                    <div className="flex flex-wrap justify-between px-4 pt-8 pb-4 mx-auto mt-4 bg-white">
                      <div className="container mb-8 text-black bg-blend-color-burnw-full">
                        <div className="grid gap-8 lg:grid-cols-6">
                          <div className="col-start-1 lg:col-start-4 xl:col-start-4">
                            <Link
                              className="block"
                              to="/about/about-novacom"
                              onClick={() => toggleExpansion(!isExpanded)}>
                              <span className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all">
                                About Novacom
                              </span>
                            </Link>
                            <Link
                              className="block mt-4"
                              to="/about/recognition"
                              onClick={() => toggleExpansion(!isExpanded)}>
                              <span className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all">
                                Recognition
                              </span>
                            </Link>
                            <Link
                              className="block mt-4"
                              to="/about/careers"
                              onClick={() => toggleExpansion(!isExpanded)}>
                              <span className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all">
                                Careers
                              </span>
                            </Link>
                          </div>
                          <LatestInsightsMegaMenu />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="flex justify-between py-3 pb-2 text-sm border-b-2 border-gray-300 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0">
                  <Link
                    className="inline-block pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all"
                    to="/services">
                    Services
                  </Link>
                  <BsChevronDown className="inline-block ml-2 text-xs text-novaBlue" />
                  <div className="z-10 w-2/3 mb-16 bg-opacity-0 mega-menu">
                    <div className="flex flex-wrap justify-between px-4 pt-8 pb-4 mx-auto bg-white">
                      <div className="container mb-8 text-black bg-blend-color-burnw-full">
                        <div className="grid gap-8 lg:grid-cols-12">
                          <div className="col-span-1 col-start-4">
                            <Link
                              className="pb-2 duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all"
                              to="/services">
                              Services
                            </Link>
                          </div>
                          <div className="col-span-2">
                            <ReachList />
                          </div>
                          <div className="col-span-4">
                            <EngageList />
                          </div>
                          <div className="col-span-2">
                            <ConvertList />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="flex justify-between py-3 pb-2 text-sm border-b-2 border-gray-300 cursor-pointer lg:px-3 xl:px-6 lg:py-6 hoverable lg:justify-start lg:inline-block lg:border-0">
                  <Link className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all">
                    Industries
                  </Link>
                  <BsChevronDown className="inline-block ml-2 text-xs text-novaBlue" />
                  <div className="z-10 w-2/3 mb-16 bg-opacity-0 mega-menu">
                    <div className="flex flex-wrap justify-between px-4 pt-8 pb-4 mx-auto mt-4 bg-white">
                      <div className="container mb-8 text-black bg-blend-color-burnw-full">
                        <div className="grid gap-8 lg:grid-cols-12">
                          <div className="col-span-6 col-start-6">
                            <IndustriesList />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="block py-3 text-sm border-b-2 border-gray-300 lg:py-6 xl:px-6 lg:px-3 lg:inline-block lg:border-0">
                  <Link
                    className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue active:border-novaBlue hover:transition-all"
                    to="/work">
                    Work
                  </Link>
                </li>

                <li className="flex justify-between py-3 pb-2 text-sm border-b-2 border-gray-300 cursor-pointer lg:px-3 xl:px-6 lg:py-6 hoverable lg:justify-start lg:inline-block lg:border-0">
                  <Link className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all">
                    Case Studies
                  </Link>
                  <BsChevronDown className="inline-block ml-2 text-xs text-novaBlue" />
                  <div className="z-10 w-2/3 mb-16 bg-opacity-0 mega-menu">
                    <div className="flex flex-wrap justify-between px-4 pt-8 pb-4 mx-auto mt-4 bg-white">
                      <div className="container mb-8 text-black bg-blend-color-burnw-full">
                        <div className="grid gap-8 lg:grid-cols-12">
                          <div className="col-span-5 col-start-7">
                            <CaseStudiesList />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="block py-3 text-sm border-b-2 border-gray-300 lg:py-6 xl:px-6 lg:px-3 lg:inline-block lg:border-0">
                  <Link
                    className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all"
                    to="/insights">
                    Insights
                  </Link>
                </li>
                <li className="block py-3 text-sm border-b-2 border-gray-300 lg:py-6 xl:px-6 lg:px-3 lg:inline-block lg:border-0">
                  <Link
                    className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all"
                    to="/partnerships">
                    Partnerships
                  </Link>
                </li>
                <li className="block py-3 text-sm border-b-2 border-gray-300 lg:py-6 xl:px-6 lg:px-3 lg:inline-block lg:border-0">
                  <Link
                    className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all"
                    to="/contact-us">
                    Contact Us
                  </Link>
                </li>
                <li className="hidden py-3 text-sm border-b-2 border-gray-300 lg:py-6 xl:px-6 lg:px-3 lg:inline-block lg:border-0">
                  <button
                    className="block py-2 mx-auto w-18"
                    onClick={setSearch}>
                    <span className="pb-2 font-light duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all">
                      {!searchIsToggled ? "Search" : "Close"}
                    </span>
                    {!searchIsToggled ? (
                      <BsSearch className="inline-block ml-2 text-md text-novaBlue" />
                    ) : (
                      <CgClose className="inline-block ml-2 text-md text-novaBlue" />
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div className="hidden lg:block">
        {/* <div className="fixed z-40 block w-screen top-14 lg:hidden"> */}

        {/* <SpringLeft> */}
        {searchIsToggled && (
          <SpringDownSearch className="relative">
            <div className="bg-novaBlue">
              <div className="container z-40 pt-4 pb-2 mx-auto">
                <SearchContainer />
              </div>
            </div>
          </SpringDownSearch>
        )}

        {!searchIsToggled && (
          <SpringUpSearch className="relative">
            <div className="bg-novaBlue">
              <div className="container z-40 pt-4 pb-2 mx-auto">
                <SearchContainer />
              </div>
            </div>
          </SpringUpSearch>
        )}
        {/* </SpringLeft> */}

        {/* </div> */}
        {/* <Slide direction={-1} distance={100} isActive={searchIsToggled}>
          <div className="bg-novaBlue">
            <div className="container z-10 pt-4 pb-2 mx-auto">
              <SearchContainer />
            </div>
          </div>
        </Slide> */}
      </div>
    </>
  );
}
