import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

export default function CaseStudiesList() {
  const { caseStudy } = useStaticQuery(graphql`
    query {
      caseStudy: allSanityCaseStudies(sort: { title: ASC }) {
        nodes {
          title
          id
          slug {
            current
          }
        }
      }
    }
  `);

  const thecasestudies = caseStudy.nodes;

  return (
    <>
      <div className="grid grid-cols-2 gap-3">
        {thecasestudies.map((caseStudy, i) => (
          <div className="col-span-1" key={i}>
            <Link
              to={`/case-studies/${caseStudy.slug.current}`}
              className="pb-2"
            >
              <div className="inline-block font-light">
                <span className="py-2 pb-2 text-sm duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all">
                  {caseStudy.title}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
