import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

export default function IndustriesList() {
  const { industries } = useStaticQuery(graphql`
    query {
      industries: allSanityIndustries(sort: { title: ASC }) {
        nodes {
          title
          id
          slug {
            current
          }
        }
      }
    }
  `);

  const theindustries = industries.nodes;

  return (
    <>
      <div className="grid grid-cols-3 gap-3">
        {theindustries.map((industries, i) => (
          <div className="col-span-1" key={i}>
            <Link
              to={`/industries/${industries.slug.current}`}
              className="pb-2 duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all"
            >
              <div className="inline-block font-light">
                <span className="py-2 text-sm">{industries.title}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
