import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

export default function EngageListFooter() {
  const { services } = useStaticQuery(graphql`
    query {
      services: allSanityServices(
        sort: { order: ASC }
        filter: { categories: { elemMatch: { title: { eq: "Engage" } } } }
      ) {
        nodes {
          title
          id
          categories {
            title
          }
          slug {
            current
          }
        }
      }
    }
  `);

  const theservices = services.nodes;

  return (
    <>
      <span className="block pb-1 text-sm font-normal">
        <Link to="/services/engage">Engage</Link>
      </span>
      <div>
        {theservices.map((services, i) => (
          <div key={i}>
            <Link to={`/services/engage#${services.slug.current}`}>
              <div className="block font-light">
                <span className="block py-1.5 text-xs text-gray-500 hover:text-gray-800">
                  {services.title}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
