import React from "react";
import { useSpring, animated as a } from "react-spring";

const SpringUpSearch = ({ children }) => {
  const animatedProps = useSpring({
    from: { marginTop: 0, opacity: 1, zIndex: -50 },
    opacity: 0,
    marginTop: -80,
    zIndex: -10,
    config: { mass: 1, tension: 150, friction: 30 },
  });
  return <a.div style={{ ...animatedProps }}>{children}</a.div>;
};

export default SpringUpSearch;
