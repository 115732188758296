import React from "react";
import Clocks from "../layout/Clocks";
import { SiteProvider } from "../utils/context";
import { SEO } from "../components/SEO";
import NewsletterSignup from "../layout/NewsletterSignup";
import Footer from "../layout/Footer";
import Nav from "../layout/Nav";
import MobileMenu from "../layout/MobileMenu";
import SocialMediaLinks from "../layout/SocialMediaLinks";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

export default function Layout({ children }) {
  return (
    <div className="debug-screens">
      <SiteProvider>
        <SEO />
        <Nav />
        <MobileMenu />
        {children}
        <SocialMediaLinks />
        <NewsletterSignup />
        <Clocks />
        <Footer />
        <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          className="font-light text-center text-gray-800 bg-white cookies CookieConsent"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ justifyContent: "center", display: "flex" }}>
          <div style={{ flex: "0 0 auto", display: "block" }}>
            This website uses cookies to give you the best experience. We take
            privacy and the security of your data very seriously, and you can
            find out more about this in our{" "}
            <Link
              className="text-white border-b border-transparent hover:border-white hover:text-white"
              to="/legal-and-privacy"
              target="blank">
              privacy policy
            </Link>
            .
          </div>
        </CookieConsent>
      </SiteProvider>
    </div>
  );
}
