import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export default function LatestInsightsMegaMenu() {
  const { latestinsightsmegamenu } = useStaticQuery(graphql`
    query {
      latestinsightsmegamenu: allSanityPost(
        sort: { publishedAt: DESC }
        limit: 2
      ) {
        nodes {
          title
          id
          publishedAt(formatString: "Do MMMM YYYY")
          slug {
            current
          }
          mainImage {
            asset {
              url
              id
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const latestinsightmm = latestinsightsmegamenu.nodes;

  return (
    <>
      {latestinsightmm.map((latestinsightsmegamenu, i) => (
        <div key={i} className="hidden w-fit lg:block">
          <Link to={`/insights/${latestinsightsmegamenu.slug.current}`}>
            <GatsbyImage
              image={latestinsightsmegamenu.mainImage.asset.gatsbyImageData}
              alt={latestinsightsmegamenu.title}
              style={{ height: "120px" }}
            />
            <span className="block py-2 font-normal">
              {latestinsightsmegamenu.title}
            </span>
          </Link>
        </div>
      ))}
    </>
  );
}
