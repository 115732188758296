import React from "react";
import Clock from "react-live-clock";

export default function Clocks() {
  return (
    <>
      <div className="z-50 bg-gray-900 border-b-8 border-novaBlue">
        <div className="container mx-auto max-w-7xl">
          <div className="grid grid-cols-3 gap-4 p-6 -mt-1 md:grid-cols-6">
            <div className="w-20 mx-auto">
              <span className="text-xs text-white">London</span>
              <p className="pt-0 text-2xl font-bold text-gray-400">
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Europe/London"}
                />
              </p>
            </div>
            <div className="w-20 mx-auto">
              <span className="text-xs text-white">Amsterdam</span>
              <p className="pt-0 text-2xl font-bold text-gray-400">
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Europe/Amsterdam"}
                />
              </p>
            </div>
            <div className="w-20 mx-auto">
              <span className="text-xs text-white">Dubai</span>
              <p className="pt-0 text-2xl font-bold text-gray-400">
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Asia/Dubai"}
                />
              </p>
            </div>
            <div className="w-20 mx-auto">
              <span className="text-xs text-white">Singapore</span>
              <p className="pt-0 text-2xl font-bold text-gray-400">
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"Asia/Singapore"}
                />
              </p>
            </div>
            <div className="w-20 mx-auto">
              <span className="text-xs text-white">San Francisco</span>
              <p className="pt-0 text-2xl font-bold text-gray-400">
                <Clock
                  format={"HH:mm"}
                  ticking={true}
                  timezone={"America/Los_Angeles"}
                />
              </p>
            </div>
            <div className="w-20 mx-auto">
              <span className="text-xs text-white">New York</span>
              <p className="pt-0 text-2xl font-bold text-gray-400">
                <Clock format={"HH:mm"} ticking={true} timezone={"EST5EDT"} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
