import { Link } from "gatsby";
import React from "react";
import ConvertListFooter from "../nav-lists/footer/ConvertListFooter";
import EngageListFooter from "../nav-lists/footer/EngageListFooter";
import IndustriesListFooter from "../nav-lists/footer/IndustriesListFooter";
import CaseStudiesListFooter from "../nav-lists/footer/CaseStudiesListFooter";
import ReachListFooter from "../nav-lists/footer/ReachListFooter";

import iso9001 from "../pdfs/ISO-9001-certificate-20230509.pdf";
import iso27001 from "../pdfs/ISO-27001-certificate-20230509.pdf";

import { RiTwitterXFill, RiLinkedinFill, RiYoutubeFill, RiFacebookFill } from "react-icons/ri";

export default function Footer() {
  return (
    <>
      <div className="bg-gray-100 md:py-8">
        <div className="w-full px-4 py-4 mx-auto md:px-12 xl:w-10/12 max-w-7xl">
          <div className="grid gap-4 lg:grid-cols-16">
            <div className="hidden col lg:col-span-2 lg:block">
              <span className="block pb-2 text-sm font-normal">About</span>
              <Link className="block py-1" to="/about/about-novacom">
                <span className="text-xs font-light text-gray-500">About Novacom</span>
              </Link>
              <Link className="block py-1" to="/about/recognition">
                <span className="text-xs font-light text-gray-500 ">Recognition</span>
              </Link>
              <Link className="block py-1" to="/about/careers">
                <span className="text-xs font-light text-gray-500">Careers</span>
              </Link>
            </div>
            <div className="hidden col lg:col-span-5 lg:block">
              <Link className="block pb-3 text-sm" to="/services">
                <span>Services</span>
              </Link>
              <div className="grid gap-2 md:grid-cols-3">
                <div className="col md:col-span-1">
                  <ReachListFooter />
                </div>
                <div className="col md:col-span-1">
                  <EngageListFooter />
                </div>
                <div className="col md:col-span-1">
                  <ConvertListFooter />
                </div>
              </div>
            </div>
            <div className="hidden col lg:col-span-2 lg:block">
              <IndustriesListFooter />
            </div>
            <div className="hidden col lg:col-span-2 lg:block">
              <CaseStudiesListFooter />
            </div>
            <div className="hidden col lg:col-span-2 lg:block">
              <Link className="block pb-1.5" to="/work">
                <span className="text-sm font-normal">Work</span>
              </Link>
              <Link className="block py-1.5" to="/insights">
                <span className="text-sm font-normal">Insights</span>
              </Link>
              <Link className="block py-1.5" to="/partnerships">
                <span className="text-sm font-normal">Partnerships</span>
              </Link>
              <Link className="block py-1.5" to="/contact-us">
                <span className="text-sm font-normal">Contact us</span>
              </Link>
            </div>
            <div className="col lg:col-span-3">
              <div className="grid grid-cols-4 gap-2 mt-6 mb-3 md:mt-0">
                <div className="col-span-1 col">
                  <div className="flex items-center justify-center lg:justify-start">
                    <a href="https://twitter.com/novacomcorp" target="_blank" rel="noopener">
                      <RiTwitterXFill className="px-2 py-1 text-5xl text-white rounded-full md:text-4xl bg-novaBlue" />
                    </a>
                  </div>
                </div>
                <div className="col-span-1 col">
                  <div className="flex items-center justify-center lg:justify-start ">
                    <a href="https://www.linkedin.com/company/novacomcorp" target="_blank" rel="noopener">
                      <RiLinkedinFill className="px-2 py-1 text-5xl text-white rounded-full md:text-4xl bg-novaBlue" />
                    </a>
                  </div>
                </div>
                <div className="col-span-1 col">
                  <div className="flex items-center justify-center lg:justify-start">
                    <a href="https://www.facebook.com/novacomcorp" target="_blank" rel="noopener">
                      <RiFacebookFill className="px-2 py-1 text-5xl text-white rounded-full md:text-4xl bg-novaBlue" />
                    </a>
                  </div>
                </div>
                <div className="col-span-1 col">
                  <div className="flex items-center justify-center lg:justify-start">
                    <a href="https://www.youtube.com/user/novacomcorp" target="_blank" rel="noopener">
                      <RiYoutubeFill className="px-2 py-1 text-5xl text-white rounded-full md:text-4xl bg-novaBlue" />
                    </a>
                  </div>
                </div>
              </div>
              <span className="block mt-6 text-sm font-medium text-center md:mt-12 lg:mt-4 text-novaBlue md:text-left">
                <a href={iso9001} target="_blank" rel="noopener noreferrer">
                  ISO 9001
                </a>
                ,{" "}
                <a href={iso27001} target="_blank" rel="noopener noreferrer">
                  ISO 27001
                </a>{" "}
                certified
              </span>
              <span className="block text-sm font-medium text-center text-novaBlue md:text-left">
                and{" "}
                <a href="https://en.wikipedia.org/wiki/General_Data_Protection_Regulation" target="_blank" rel="noopener noreferrer">
                  100% GDPR compliant
                </a>
              </span>
              <p className="pr-1 text-xs leading-relaxed text-center lg:mt-32 md:text-left">
                Novacom is an award winning B2B digital marketing and customer experience agency focusing on delivering transformation initiatives that deliver real value for our global clients.
              </p>
              <p className="text-xs leading-relaxed text-center md:text-left">
                © {new Date().getFullYear()} Novacom Group Limited. All rights reserved.{" "}
                <Link to="/legal-and-privacy" className="text-novaBlue">
                  Legal and Privacy
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
