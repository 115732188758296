import React from "react";

import { useGlobalContext } from "../utils/context";
import Slide from "../utils/Slide";
import SlideX from "../utils/SlideX";

import SearchContainer from "../search/SearchContainer";

import { BsArrowLeft, BsArrowRight, BsSearch } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { HiOutlineMenu } from "react-icons/hi";
import { Link } from "gatsby";

import logo from "../images/novacom-logo.svg";
import SpringDownSearch from "../utils/springDownSearch";
import SpringUpSearch from "../utils/springUpSearch";

export default function MobileMenu() {
  const {
    searchIsToggled,
    setSearch,
    navBarIsToggled,
    setNavBar,
    closeNavBar,
    aboutMenuIsToggled,
    setAboutMenu,
    closeAboutMenu,
    servicesMenuIsToggled,
    setServicesMenu,
    closeServicesMenu,
    industriesMenuIsToggled,
    setIndustriesMenu,
    closeIndustriesMenu,
    caseStudiesMenuIsToggled,
    setCaseStudiesMenu,
    closeCaseStudiesMenu,
  } = useGlobalContext();

  return (
    <>
      <div className="sticky top-0 z-50 bg-white lg:hidden">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <div className="px-2">
            <button
              onClick={closeAboutMenu && setNavBar}
              className="items-center block hover:text-black hover:border-black"
            >
              <span>
                {!navBarIsToggled ? (
                  <HiOutlineMenu className="inline-block ml-2 text-3xl text-novaBlue" />
                ) : (
                  <CgClose className="inline-block ml-2 text-3xl text-novaBlue" />
                )}
              </span>
            </button>
          </div>
          <div className="block gap-2 px-3 pt-3 pb-3 text-xl font-bold text-blue-600 lg:pl-0 lg:inline-block lg:py-6">
            <Link to="/">
              <img src={logo} alt="Novacom" className="w-40 pt-1 pb-2" />
            </Link>
          </div>
          <div className="px-2">
            <button onClick={setSearch} className="pb-2 font-light">
              {!searchIsToggled ? (
                <BsSearch className="inline-block ml-2 text-2xl text-md text-novaBlue" />
              ) : (
                <CgClose className="inline-block ml-2 text-2xl text-md text-novaBlue" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="fixed z-40 block top-14 lg:hidden md:w-1/2">
        <Slide direction={-1} distance={1000} isActive={navBarIsToggled}>
          <div className="">
            <ul className="w-screen h-screen px-4 pt-2 bg-white top-14 md:w-full">
              <li className="flex justify-between px-4 py-3 pb-2">
                <div
                  className="pb-2 font-light border-b-2 border-transparent cursor-pointer hover:border-novaBlue current:border-novaBlue"
                  onClick={setAboutMenu}
                  onKeyDown={setAboutMenu}
                >
                  About
                </div>
                <div
                  className="pb-2 text-3xl font-light cursor-pointer text-novaBlue"
                  onClick={setAboutMenu}
                  onKeyDown={setAboutMenu}
                >
                  <BsArrowRight />
                </div>
              </li>

              {/* About SubMenu */}

              <div className="fixed top-0 z-40 block lg:hidden md:w-1/2 md:pl-4">
                <SlideX
                  direction={1}
                  distance={1000}
                  isActive={aboutMenuIsToggled}
                >
                  <ul className="fixed w-screen h-screen px-4 pt-2 bg-white top-14 md:w-5/12">
                    <li className="flex py-3 pb-2 cursor-pointer">
                      <div
                        className="pb-2 text-3xl font-light text-novaBlue"
                        onClick={closeAboutMenu}
                        onKeyDown={closeAboutMenu}
                      >
                        <BsArrowLeft className="mr-2 -ml-10" />
                      </div>
                      <div onClick={closeAboutMenu} onKeyDown={closeAboutMenu}>
                        <div className="block pb-2 mt-1 font-medium text-novaBlue">
                          About
                        </div>
                      </div>
                    </li>

                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeAboutMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/about/about-novacom"
                        onClick={closeNavBar}
                      >
                        About Novacom
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeAboutMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/about/recognition"
                        onClick={closeNavBar}
                      >
                        Recognition
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeAboutMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/about/careers"
                        onClick={closeNavBar}
                      >
                        Careers
                      </Link>
                    </li>
                  </ul>
                </SlideX>
              </div>

              <li className="flex justify-between px-4 py-3 pb-2">
                <div
                  className="pb-2 font-light border-b-2 border-transparent cursor-pointer hover:border-novaBlue current:border-novaBlue"
                  onClick={setServicesMenu}
                >
                  Services
                </div>
                <div
                  className="pb-2 text-3xl font-light cursor-pointer text-novaBlue"
                  onClick={setServicesMenu}
                >
                  <BsArrowRight />
                </div>
              </li>

              {/* Services SubMenu */}

              <div className="fixed top-0 z-40 block lg:hidden md:w-1/2 md:pl-4">
                <SlideX
                  direction={1}
                  distance={1000}
                  isActive={servicesMenuIsToggled}
                >
                  <ul className="fixed w-screen h-screen px-4 pt-2 bg-white top-14 md:w-5/12">
                    <li className="flex py-3 pb-2 cursor-pointer">
                      <div
                        className="pb-2 text-3xl font-light text-novaBlue"
                        onClick={closeServicesMenu}
                      >
                        <BsArrowLeft className="mr-2 -ml-10" />
                      </div>
                      <div onClick={closeServicesMenu}>
                        <Link
                          className="block pb-2 mt-1 text-lg font-medium text-novaBlue"
                          to="/services"
                          onClick={closeNavBar}
                        >
                          Services
                        </Link>
                      </div>
                    </li>

                    <li
                      className="py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeServicesMenu}
                    >
                      <Link
                        className="block pb-2 text-lg"
                        to="/services/reach"
                        onClick={closeNavBar}
                      >
                        Reach
                      </Link>
                      <span className="block mb-2 font-light">
                        Connected experiences that build valuable relationships
                      </span>
                    </li>
                    <li
                      className="py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeServicesMenu}
                    >
                      <Link
                        className="block pb-2 text-lg"
                        to="/services/engage"
                        onClick={closeNavBar}
                      >
                        Engage
                      </Link>
                      <span className="block mb-2 font-light">
                        Inspire your customers with every interaction
                      </span>
                    </li>
                    <li
                      className="py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeServicesMenu}
                    >
                      <Link
                        className="block pb-2 text-lg"
                        to="/services/convert"
                        onClick={closeNavBar}
                      >
                        Convert
                      </Link>
                      <span className="block mb-2 font-light">
                        Seamless experiences and technologies that redefine
                        performance
                      </span>
                    </li>
                  </ul>
                </SlideX>
              </div>

              <li className="flex justify-between px-4 py-3 pb-2">
                <div
                  className="pb-2 font-light border-b-2 border-transparent cursor-pointer hover:border-novaBlue current:border-novaBlue"
                  onClick={setIndustriesMenu}
                >
                  Industries
                </div>
                <div
                  className="pb-2 text-3xl font-light cursor-pointer text-novaBlue"
                  onClick={setIndustriesMenu}
                >
                  <BsArrowRight />
                </div>
              </li>

              {/* Industries SubMenu */}

              <div className="fixed top-0 z-40 block lg:hidden md:w-1/2 md:pl-4">
                <SlideX
                  direction={1}
                  distance={1000}
                  isActive={industriesMenuIsToggled}
                >
                  <ul className="fixed w-screen h-screen px-4 pt-2 bg-white top-14 md:w-5/12">
                    <li className="flex py-3 pb-2 cursor-pointer">
                      <div
                        className="pb-2 text-3xl font-light text-novaBlue"
                        onClick={closeIndustriesMenu}
                      >
                        <BsArrowLeft className="mr-2 -ml-10" />
                      </div>
                      <div onClick={closeIndustriesMenu}>
                        <div className="block pb-2 mt-1 font-medium text-novaBlue">
                          Industries
                        </div>
                      </div>
                    </li>

                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/agriculture"
                        onClick={closeNavBar}
                      >
                        Agriculture
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/automotive-and-mobility-solutions"
                        onClick={closeNavBar}
                      >
                        Automotive and mobility solutions
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/commodities"
                        onClick={closeNavBar}
                      >
                        Commodities
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/energy"
                        onClick={closeNavBar}
                      >
                        Energy
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/financial-services"
                        onClick={closeNavBar}
                      >
                        Financial services
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/global-marine"
                        onClick={closeNavBar}
                      >
                        Global marine
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/industrial"
                        onClick={closeNavBar}
                      >
                        Industrial
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/professional-services"
                        onClick={closeNavBar}
                      >
                        Professional services
                      </Link>
                    </li>
                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeIndustriesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/industries/public-utilities"
                        onClick={closeNavBar}
                      >
                        Public utilities
                      </Link>
                    </li>
                  </ul>
                </SlideX>
              </div>

              <li className="flex justify-between px-4 py-3 pb-2">
                <Link
                  className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                  to="/work"
                  onClick={closeNavBar}
                >
                  Work
                </Link>
              </li>

              <li className="flex justify-between px-4 py-3 pb-2">
                <div
                  className="pb-2 font-light border-b-2 border-transparent cursor-pointer hover:border-novaBlue current:border-novaBlue"
                  onClick={setCaseStudiesMenu}
                >
                  Case Studies
                </div>
                <div
                  className="pb-2 text-3xl font-light cursor-pointer text-novaBlue"
                  onClick={setCaseStudiesMenu}
                >
                  <BsArrowRight />
                </div>
              </li>

              {/* Case Studies SubMenu */}

              <div className="fixed top-0 z-40 block lg:hidden md:w-1/2 md:pl-4">
                <SlideX
                  direction={1}
                  distance={1000}
                  isActive={caseStudiesMenuIsToggled}
                >
                  <ul className="fixed w-screen h-screen px-4 pt-2 bg-white top-14 md:w-5/12">
                    <li className="flex py-3 pb-2 cursor-pointer">
                      <div
                        className="pb-2 text-3xl font-light text-novaBlue"
                        onClick={closeCaseStudiesMenu}
                      >
                        <BsArrowLeft className="mr-2 -ml-10" />
                      </div>
                      <div onClick={closeCaseStudiesMenu}>
                        <div className="block pb-2 mt-1 font-medium text-novaBlue">
                          Case Studies
                        </div>
                      </div>
                    </li>

                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeCaseStudiesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/case-studies/chevron-marine-ultra-vessel"
                        onClick={closeNavBar}
                      >
                        Chevron Marine Ultra Vessel
                      </Link>
                    </li>

                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeCaseStudiesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/case-studies/immersive-journeys-that-engage"
                        onClick={closeNavBar}
                      >
                        Immersive journeys that engage
                      </Link>
                    </li>

                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeCaseStudiesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/case-studies/insurance"
                        onClick={closeNavBar}
                      >
                        Insurance
                      </Link>
                    </li>

                    <li
                      className="flex justify-between py-3 pb-2 lg:px-3 xl:px-6 hoverable lg:justify-start lg:inline-block lg:border-0"
                      onClick={closeCaseStudiesMenu}
                    >
                      <Link
                        className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                        to="/case-studies/texaco-hdax-power-generation-campaign"
                        onClick={closeNavBar}
                      >
                        Texaco HDAX power generation campaign
                      </Link>
                    </li>
                  </ul>
                </SlideX>
              </div>

              <li className="flex justify-between px-4 py-3 pb-2">
                <Link
                  className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                  to="/insights"
                  onClick={closeNavBar}
                >
                  Insights
                </Link>
              </li>
              <li className="flex justify-between px-4 py-3 pb-2">
                <Link
                  className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                  to="/partnerships"
                  onClick={closeNavBar}
                >
                  Partnerships
                </Link>
              </li>
              <li className="flex justify-between px-4 py-3 pb-2">
                <Link
                  className="pb-2 font-light border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue"
                  to="/contact-us"
                  onClick={closeNavBar}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </Slide>
      </div>

      {/* Search Container */}

      <div className="fixed z-40 block w-screen top-14 lg:hidden">
        {searchIsToggled ? (
          <SpringDownSearch>
            <div className="bg-novaBlue">
              <div className="container z-10 pt-4 pb-2 mx-auto">
                <SearchContainer />
              </div>
            </div>
          </SpringDownSearch>
        ) : (
          <SpringUpSearch>
            <div className="bg-novaBlue">
              <div className="container z-10 pt-4 pb-2 mx-auto">
                <SearchContainer />
              </div>
            </div>
          </SpringUpSearch>
        )}
      </div>
    </>
  );
}
