import React, { useState, useEffect, useCallback } from "react";
import { navigate } from "gatsby";
import * as JsSearch from "js-search";
import searchIndex from "./searchIndex.json";
import { BsArrowRight, BsSearch } from "react-icons/bs";
import { useGlobalContext } from "../utils/context";

export default function SearchContainer() {
  const [search, setSearch] = useState({
    results: [],
    engine: {},
    query: "",
  });

  const rebuildIndex = useCallback(() => {
    const searchEngine = new JsSearch.Search("slug");
    searchEngine.addIndex("title");
    searchEngine.addIndex("excerpt");
    searchEngine.addIndex("category");
    searchEngine.addDocuments(searchIndex.insights);

    setSearch((search) => {
      return {
        ...search,
        engine: searchEngine,
      };
    });
  }, []);

  useEffect(() => {
    rebuildIndex();
  }, [rebuildIndex]);

  const performSearch = (event) => {
    const { value } = event.target;
    const results = search.engine.search(value);
    setSearch({ ...search, results, query: value });
  };

  const { closeSearch } = useGlobalContext();

  return (
    <>
      <div className="container mx-auto">
        <div className="flex">
          <div className="flex-initial">
            <BsSearch className="inline-block ml-2 mr-8 -mb-4 text-2xl text-white" />
          </div>
          <div className="flex-1">
            <input
              onChange={performSearch}
              value={search.query}
              className="inline-block min-w-full mb-4 font-light text-white placeholder-white border-0 input bg-novaBlue focus:ring-white"
              type="text"
              placeholder="Search"
            />
          </div>
        </div>
        {search.results.length > 0 && (
          <div className="overflow-scroll overflow-y-auto max-h-120">
            <div className="grid grid-cols-1 gap-4 pr-2 mb-4 border-t-2 border-white md:grid-cols-4 xl:grid-cols-5 bg-novaBlue">
              {search.results.map((result) => (
                <div
                  onClick={() => navigate(`${result.slug}`)}
                  role="presentation"
                  key={result.slug}
                  className="pt-4 pb-2 cursor-pointer"
                >
                  <p className="pb-0 text-sm text-white" onClick={closeSearch}>
                    {result.category}
                  </p>
                  <p
                    className="text-lg leading-tight text-white h-18 line-clamp-3"
                    onClick={closeSearch}
                  >
                    {result.title}
                  </p>
                  <p
                    className="items-center lg:flex-initial lg:w-max"
                    onClick={closeSearch}
                  >
                    <p className="flex justify-center px-4 py-2 text-sm font-light transition bg-white text-novaBlue lg:justify-start hover:bg-opacity-80">
                      Learn more <BsArrowRight className="ml-2 text-lg" />
                    </p>
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
