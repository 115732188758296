import React from "react";
import { useFormik } from "formik";
import { HiMail } from "react-icons/hi";
import { BsArrowRight } from "react-icons/bs";

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values) => {
  const errors = {};

  if (!values.field_18Yes) {
    errors.field_18Yes = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

export const NewsletterFooterForm = ({ formID }) => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues: {
      email: "",
      field_18Yes: "",
    },
    validate,
    // onSubmit: (values) => {
    //   alert(JSON.stringify(values, null, 2));
    // },
  });

  return (
    <>
      <div className="mx-auto lg:container lg:w-11/12">
        <div className="px-4 py-8">
          <form
            method="POST"
            action="https://novacom.ascenta.systems/proc.php"
            // noValidate
            // onSubmit={formik.handleSubmit}
          >
            <input type="hidden" name="u" value={formID} />
            <input type="hidden" name="f" value={formID} />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <div className="grid grid-cols-12 gap-2 border-b-2 border-novaBlue">
              <div className="grid col-span-12 lg:col-span-1">
                <HiMail className="hidden mt-2 ml-2 mr-8 -mb-4 text-2xl text-white lg:inline-block" />
              </div>
              <div className="grid col-span-12 lg:col-span-8">
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="pb-4 font-light text-white placeholder-white bg-gray-900 border-0 input focus:ring-0"
                  placeholder="Sign up to our newsletter by entering your email address here"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="pl-3 -mt-3 font-bold text-red-600">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="grid col-span-12 lg:col-span-3">
                <div className="flex items-center">
                  <label
                    htmlFor="field_18Yes"
                    className="block pr-8 -mt-6 text-xs font-light text-white lg:ml-2">
                    Are you happy for us to contact you with this newsletter and
                    related information?
                  </label>
                  <label
                    htmlFor="field_18Yes"
                    className="block pr-2 ml-2 -mt-6 text-sm font-light text-white">
                    Yes
                  </label>
                  <input
                    id="field_18Yes"
                    name="field_18Yes"
                    type="checkbox"
                    className="w-4 h-4 mb-4 border-novaBlue text-novaBlue focus:ring-novaBlue bg-novaBlue"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.field_18Yes}
                  />
                </div>
              </div>
            </div>
            <input type="hidden" name="field[25]" value="" />
            <button
              className="flex justify-center px-4 py-2 mx-auto mt-4 font-light text-white transition submit bg-novaBlue hover:bg-opacity-80"
              // role="submit"
              type="submit">
              Submit <BsArrowRight className="ml-2 mt-0.5 text-lg" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
