import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

export default function ReachList() {
  const { services } = useStaticQuery(graphql`
    query {
      services: allSanityServices(
        sort: { order: ASC }
        filter: { categories: { elemMatch: { title: { eq: "Reach" } } } }
      ) {
        nodes {
          title
          id
          categories {
            title
          }
          slug {
            current
          }
        }
      }
    }
  `);

  const theservices = services.nodes;

  return (
    <>
      <span className="pb-1 font-medium border-b-2 text-md border-novaBlue">
        <Link to="/services/reach">Reach</Link>
      </span>
      <div className="mt-4">
        {theservices.map((services, i) => (
          <div key={i}>
            <Link to={`/services/reach#${services.slug.current}`} className="">
              <div className="block mt-3 font-light ">
                <span className="py-2 pb-2 text-sm duration-200 border-b-2 border-transparent hover:border-novaBlue current:border-novaBlue hover:transition-all">
                  {services.title}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
